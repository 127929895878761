<template>
  <div>
    <el-cascader
      ref="cascader"
      :value="value"
      :options="options"
      v-bind="$attrs"
      :props="props"
      :collapse-tags="collapseTags"
      :clearable="clearable"
      :placeholder="placeholder"
      :disabled="disabled"
      :show-all-levels="showAllLevels"
      :separator="separator"
      :debounce="debounce"
      :popper-class="popperClass"
      :empty="empty"
      filterable
      @change="$emit('change', $event)"
      @expand-change="$emit('expand-change',$event)"
      @blur="$emit('blur',$event)"
      @focus="$emit('focus',$event)"
      @visible-change="$emit('visible-change',$event)"
      @remove-tag="$emit('remove-tag',$event)"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import apiMapList from './apiMapList'
import { isEqual } from 'lodash'
export default {
  name: 'CascadeSelector',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    apiKey: {
      type: String,
      default: ''
    },
    collapseTags: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    // 输入框占位文本
    placeholder: {
      type: String,
      default: '请选择'
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 输入框中是否显示选中值的完整路径
    showAllLevels: {
      type: Boolean,
      default: true
    },
    // 是否可搜索选项
    separator: {
      type: String,
      default: '/ '
    },
    permission: {
      type: Boolean,
      default: true
    },
    // 搜索关键词输入的去抖延迟，毫秒
    debounce: {
      type: Number,
      default: 300
    },
    // 自定义浮层类名
    popperClass: {
      type: String,
      default: ''
    },
    // 无匹配选项时的内容
    empty: {
      type: String,
      default: ''
    },
    // 级联需要忽略的参数
    gangedIgnore: {
      type: Array,
      default: () => []
    },
    // 是否需要级联
    ganged: {
      type: Boolean,
      default: false
    },
    // 参数
    params: {
      type: Object,
      default: () => ({})
    },
    props: {
      type: Object,
      default: () => ({
        // 	次级菜单的展开方式 click / hover
        expandTrigger: 'click',
        // 是否多选
        multiple: true,
        // 是否严格的遵守父子节点不互相关联
        checkStrictly: false,
        // 在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
        emitPath: true,
        value: 'value',
        label: 'label',
        children: 'children'
      })
    },
    handleResponseData: {
      type: Function,
      default: (val) => { return val }
    }
  },
  data() {
    return {
      options: []
    }
  },
  computed: {
    apiParams() {
      return Object.assign({}, this.params)
    },

    // 是否传入了参数
    isCommitParams() {
      return Object.keys(this.params).every(key => {
        // 是否是级联需要忽略的参数
        const isGangedIgnore = this.gangedIgnore.includes(key)
        //  是否存在
        const isExist = Array.isArray(this.params[key]) ? this.params[key].length !== 0 : !!this.params[key]
        return isGangedIgnore || isExist
      }) && Object.keys(this.params).length !== 0
    }
  },
  watch: {
    // 监控参数
    'params': {
      handler(params, lodParams) {
        this.getOptions(params, lodParams)
      },
      deep: true
    }
  },
  mounted() {
    if ((!this.isCommitParams && !this.ganged) || this.notRequiredParams) {
      this._getOptions()
    }
  },
  methods: {

    getOptions(params, lodParams) {
      // 执行深比较来确定两者的值是否相等。
      if (isEqual(params, lodParams)) return
      // 是否联调
      if (this.ganged) {
        // 是否传入了参数
        if (this.isCommitParams || this.notRequiredParams) {
          this._getOptions()
        } else {
          this.options = []
        }
      }
    },

    async _getOptions() {
      const { api, configuration } = apiMapList.find(item => item.key === this.apiKey) || {}
      const { datas } = await api(this.apiParams)
      // this.options = this.formation(data, configuration)
      this.options = this.handleResponseData(datas)
      this.$emit('responseData', datas)
    },
    formation(data, configuration) {
      const options = []
      data.forEach(item => {
        const current = { ...item }
        if (current[configuration.children]) {
          current.children = this.formation(current[configuration.children], configuration.childrenConfiguration)
        }
        current.value = current[configuration.value]
        current.label = current[configuration.label]
        options.push(current)
      })
      return options
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
