<template>
  <div>
    <QueryForm v-model="queryForm" show-export :query-btn-loading="tableLoading" @handleExport="handleExport" @query="handleQuery" @reset="handleQueryFormReset">
      <el-form-item label="起止日期">
        <el-date-picker
          v-model="queryForm.value"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width:100%;"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item label="销售渠道">
        <Cascader
          v-model="queryForm.stockChannelNameList"
          :handle-response-data="getDataTree"
          api-key="getplatformSiteShop"
          :props="{
            value: 'value',
            label: 'dropDownName',
            children: 'dropDownList',
            multiple: true,
          }"
        />
      </el-form-item>
      <el-form-item label="产品类目">
        <Cascader
          v-model="queryForm.productCategoryNameList"
          separator=">"
          api-key="productCategoryApi"
          :props="{
            value: 'categoryCnName',
            label: 'categoryCnName',
            children: 'childList',
            multiple: true,
          }"
        />

      </el-form-item>
      <el-form-item label="商品类目">
        <Cascader
          v-model="queryForm.categoryNameList"
          separator=">"
          api-key="getBasecategory"
          :props="{
            value: 'categoryName',
            label: 'categoryName',
            children: 'sonCategory',
            multiple: true,
          }"
        />

      </el-form-item>
      <el-form-item label="品牌">
        <Select
          v-model="queryForm.brandNameList"
          api-key="brandList"
          clearable
          :configuration=" {
            key: 'id',
            label: 'brandName',
            value: 'brandName'
          }"
          multiple
        />
      </el-form-item>

      <el-form-item label="产品定位">
        <Select
          v-model="queryForm.positionNameList"
          style="width:100%;"
          api-key="position"
          :configuration="{
            key: 'stylePositionId',
            label: 'position',
            value: 'position'
          }"
          clearable
          filterable
          collapse-tags
          multiple
        />
      </el-form-item>

      <el-form-item label="BG">
        <Select
          v-model="queryForm.bgNameList"
          api-key="brandList"
          clearable
          multiple
          :configuration=" {
            key: 'id',
            label: 'brandName',
            value: 'brandName'
          }"
        />
      </el-form-item>
      <el-form-item label="BU">
        <Select
          v-model="queryForm.buNameList"
          api-key="buList"
          clearable
          multiple
          :configuration="{
            key: 'id',
            label: 'buName',
            value: 'buName'
          }"
        />
      </el-form-item>
      <el-form-item label="适用季节">
        <Select
          v-model="queryForm.seasonNameList"
          :select-options="_getAllCommodityDict('SEASON')"
          :configuration=" { key: 'val', label: 'label', value: 'label' }"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="上市季节">
        <Select
          v-model="queryForm.launchSeasonDictValueList"
          api-key="listLaunchSeason"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="Style">
        <Select
          v-model="queryForm.styleId"
          api-key="styleList"
          clearable
          @change="handleChangeStyle"
          @responseData="val=>styleList=val"
        />
      </el-form-item>
      <el-form-item label="Color">
        <Select
          v-model="queryForm.colorNameList"
          api-key="colorList"
          :params="{ styleId : queryForm.styleId }"
          ganged
          :configuration=" { key: 'id', label: 'colorName', value: 'colorName' }"
          not-required-params
          multiple
          collapse-tags
        />
      </el-form-item>
      <el-form-item label="Size">
        <Select
          v-model="queryForm.sizeNameList"
          not-required-params
          api-key="sizeList"
          :params="{ styleId : queryForm.styleId}"
          ganged
          multiple
          collapse-tags
          :configuration=" { key: 'id', label: 'size', value: 'size' }"
        />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input v-model="queryForm.skuNameList" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>

    </QueryForm>

    <Table
      :table-data="tableData"
      max-height="600px"
      :columns="columns.salesStaticsTableColumns"
      :loading="tableLoading"
    />
    <Paging :pager="pager" end class="mt-3" @pagination="pagerUpdate" />

  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import Cascader from '@/components/Cascader'
import { statsFcstPageList, getBasecategory, exportStatusFcst } from '@/api/configuration'
import Table from '@/components/Table'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Pagination'
import columns from '@/constant/tablecolumns'
import { productCategoryApi } from '@/api/listSelection'
import { handlePageParams } from '@/utils'

export default {
  components: { QueryForm, Table, Select, Paging, Cascader },
  mixins: [commodityInfoDict],

  data() {
    return {
      pickerOptions: {
        disabledDate: () => false,
        onPick: ({ maxDate, minDate }) => {
          if (minDate && !maxDate) {
            const oneYear = 365 * 24 * 60 * 60 * 1000
            this.pickerOptions.disabledDate = time => {
              return time.getTime() < minDate.getTime() || time.getTime() > minDate.getTime() + oneYear
            }
          } else {
            this.pickerOptions.disabledDate = () => false
          }
        }
      },

      columns,
      tableData: [],
      queryForm: {
        styleId: '',
        value: [],
        categoryNameList: [],
        sizeNameList: []
      },
      tableLoading: false,
      styleList: [],
      pager: {
        size: 10,
        current: 1
      },
      selectData: ''
    }
  },
  computed: {
    qeuryParams() {
      return handlePageParams({
        formData: this.queryForm,
        processedParameter: {
          timeField: {
            value: ['startDate', 'endDate']
          },
          lastCategoryIdList: ['categoryNameList', 'productCategoryNameList'],
          toArrayList: ['skuNameList', 'styleNameList'],
          findNameOrIdList: {
            styleNameList: ['styleId', 'id', 'styleName', this.styleList]
          },
          platSiteShopList: ['stockChannelNameList']
        }

      })
    }
  },
  mounted() {
    this.handleQuery()
  },
  methods: {
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].dropDownId}&&${data[i].dropDownName}`
        this.getDataTree(data[i].dropDownList)
      }
      return data
    },
    async handleExport() {
      await exportStatusFcst(this.qeuryParams)
      this.$notify({
        message: '操作成功，请前往文件导出界面下载',
        type: 'success'
      })
    },

    pagerUpdate(val) {
      this.pager = val
      this.handleClick()
    },
    handleChangeStyle() {
      this.queryForm.colorNameList = []
      this.queryForm.sizeNameList = []
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    handleQuery() {
      this.pager.current = 1
      this.handleClick()
    },
    async handleClick() {
      try {
        this.tableLoading = true
        const { datas: { records = [], current, size, total }} = await statsFcstPageList(this.pager, this.qeuryParams)
        this.tableData = records
        this.pager = { current, size, total }
      } finally {
        this.tableLoading = false
      }
    }

  }
}
</script>

<style>

</style>
