import { productCategoryApi } from '@/api/listSelection'
import { getplatformSiteShop } from '@/api/scm-api'
import { getBasecategory } from '@/api/configuration'

export default [
  {
    key: 'getplatformSiteShop',
    api: getplatformSiteShop
  },
  {
    key: 'productCategoryApi',
    api: productCategoryApi
  },
  {
    key: 'getBasecategory',
    api: getBasecategory
  }
]
